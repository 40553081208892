const apps = require("./apps");
const { getSession } = require("./session");
const Axios = require("./api/AxiosConnect");
const { alerts } = require("./swalAlerts");

module.exports = {
  getMain: function() {
    const self = module.exports;
    return self.getApp("autologin");
  },
  getHost: function(url) {
    const self = module.exports;
    url ||= self.getMain().link;
    const theurl = new URL(url);
    return theurl.origin;
  },
  getApp: function(key) {
    const app = apps.apps_icons.find((app) => app.key === key);
    return app;
  },
  getAppLink: function(key) {
    const app = apps.apps_icons.find((app) => app.key === key);
    if (app) {
      return app.link;
    }
    return null;
  },
  getApps: function() {
    return apps.apps_icons;
  },
  getAuthLnk: function(key) {
    const self = module.exports;
    const app = self.getApp(key);
    const sstk = getSession()?.SSID;
    const appHost = self.getHost(app.link);
    return `${appHost}/auth/?sstk=${sstk}&app=${app.key}`;
  },
  openOutApp: (app, authLink, sstk, isDesktopApp) => {
    if (isDesktopApp) {
      parent.postMessage({ message: "openApp", data: { app, auth: authLink, sstk } }, "*");
      return;
    }

    const appWindow = window.open(authLink, "_blank");
    appWindow.location.href = authLink;
    appWindow.focus();
  },
  goToApp: async (app, user, tokenName, appName, isDesktopApp, redirect) => {
    const self = module.exports;
    const verifyIp = await self.verifyAppRequireIp(app, user, tokenName);

    if (!verifyIp) {
      alerts.dataInfo({
        title: "Lo sentimos no se encuentra en ninguna de nuestras sedes",
        message: "Una vez te encuentres ubicado en alguna de nuestras sedes podrás acceder sin problema",
        icon: "info",
        iconColor: "#bd0909",
        confirmButtonColor: "#bd0909",
      });
      return;
    }

    if (app.key === appName) {
      window.location = redirect ? redirect : "/main";
      return;
    }

    const appHost = self.getHost(app.link);
    const sstk = user.SSID;
    let authLink = `${appHost}/auth/?sstk=${sstk}&app=${app.key}`;

    if (redirect) {
      authLink += `&redirect=${redirect}`;
    }
    if (app.out) {
      self.openOutApp(app, authLink, sstk, isDesktopApp);
      return;
    }

    localStorage.removeItem(tokenName);
    location.href = authLink;
  },
  verifyAppRequireIp: async (app, user, tokenName) => {
    try {
      localStorage.removeItem("ipAllowed");

      const setAllowed = () => localStorage.setItem("ipAllowed", true);

      if (!app?.ipLimit) {
        return true;
      }
      if (user.role === "superadmin") {
        setAllowed();
        return true;
      }

      const self = module.exports;
      const mainApp = self.getMain();
      const payload = { baseURL: mainApp.api, tokenName };
      const { data } = await Axios.instance(payload).get(`/location`);
      const isAllowed = data?.isAllowed || false;

      if (isAllowed) {
        setAllowed();
      }

      return isAllowed;
    } catch (error) {
      console.log(`verifyAppRequireIp: ${error.message}`);
    }
  },
};
