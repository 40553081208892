const axios = require("axios");

class Axios {
  constructor() {
    this.timeout = 10000;
    this.headers = {
      "Content-Type": "application/json",
    };
  }

  getAuthToken(tokenName) {
    if (!tokenName) {
      return;
    }

    return localStorage.getItem(tokenName);
  }

  instance(payload) {
    const { baseURL, timeout, headers = {}, tokenName } = payload;
    const instance = axios.create({
      baseURL,
      timeout: timeout || this.timeout,
      headers: { ...headers, ...this.headers },
    });

    instance.interceptors.request.use(
      (config) => {
        const token = this.getAuthToken(tokenName);

        if (token) {
          config.headers["Authorization"] = `${token}`;
        }
        return config;
      },
      (error) => {
        console.error("Request error:", error);
        return Promise.reject(error);
      }
    );
    return instance;
  }
}

module.exports = new Axios();
