<script>
  import { getApp, getHost } from "@/common/app.main.js";
  import layoutError from "./LayoutError.vue";

  export default {
    components: { layoutError },
    methods: {
      onSubmit() {
        const autologinApp = getApp("autologin");
        const autologinHost = getHost(autologinApp.link);
        const app = getApp(process.env.VUE_APP_KEY_NAME);
        const appHost = getHost(app.link);
        const loginUrl = encodeURI(`${autologinHost}/login?redirect=${appHost}&app=${app.key}`);

        window.location.href = loginUrl;
      },
    },
  };
</script>

<template>
  <layoutError @onSubmit="onSubmit">
    <template v-slot:title>Lo sentimos, tu sesión caducó.</template>
    <template v-slot:body>Por favor, inicie sesión nuevamente.</template>
  </layoutError>
</template>
